.site-wrapper {
  background-color: dimgray;
  min-height: 100%;
  background-color: #f9aba7;
}
.study-backgound {
  background-color: dimgrey;
}

.menu .item a {
  color: #ff4d71;
}
